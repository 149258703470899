// 引入封装的第一层axios接口
import request from "@/utils/req";

// 获取推荐点播课程详情
export const getCourseDetail = (params) => {
    return request({
        url: '/api/blade-resources/data/getDetail',
        method: 'get',
        params: {
            ...params
        }
    })
}
// 获取推荐课程聊天消息
export const getCourseMessage = (params) => {
    return request({
        url: '/api/blade-resources/telecast/getTelecastChatPage',
        method: 'get',
        params: {
            ...params
        }
    })
}
