<template>
  <div class="box" v-loading.fullscreen.lock="loading">
    <video-player class="video-player vjs-custom-skin"
                  ref="videoPlayer"
                  v-show="courseInfo.types!='html'"
                  :playsinline="playsinline"
                  :options="playerOptions"
    />
    <div class="course-head m_t_15 bg_fff p_in_20 flex_row">
      <img :src="`${courseInfo.coverUrl?imgToRespomse(courseInfo.coverUrl):defaultImg}`"
           style="width:500px;max-height: 600px;object-fit: contain;"
           :alt="courseInfo.name">
      <div class="fs_16 p_lr_20 m_tb_10 w80" style="position: relative;">
        <div class="flex">
          <div class="fs_24 m_tb_10 flex1">{{ courseInfo.name }}</div>
          <div class="">
            <div class="flex algin pointer" v-if="!isShoucang" @click="collection(courseInfo.id)">
              <el-image
                  class="scang"
                  :src="shoucang"
              ></el-image>
              <div class="txt_light m_l_02">收藏</div>
            </div>

            <div class="flex algin pointer" v-if="isShoucang" @click="cancelCollection(courseInfo.collectionId)">
              <el-image
                  class="scang"
                  :src="shoucangSelect"
              ></el-image>
              <div class="txt_light m_l_02">取消收藏</div>
            </div>
          </div>
        </div>
        <div class="fs_14 m_tb_10 c_666">
          <span>{{ courseInfo.browseNumber }}人看过</span>
          <span class="m_lr_20">
            <i class="icon-font el-icon-star-on c_orange fs_18"></i>
            <span>{{ courseInfo.collectionNumber }}</span>
          </span>
        </div>
        <div class="m_tb_10">{{ courseInfo.remarks1 }}</div>
        <el-button class="htmlLink" size="small" v-show="courseInfo.types === 'html'" plain type="primary"><a
            :href="courseInfo.remarks2" target="_blank">前往学习</a>
        </el-button>
      </div>
    </div>

    <div class="course-body m_t_15 bg_fff p_in_20 fs_14" v-if="courseInfo.teacher||courseInfo.isTelecast === '1'">
      <el-tabs value="first">
        <el-tab-pane label="课程介绍" name="first" style="line-height: 30px">
          <div v-html="courseInfo.introduce"></div>
          <el-empty description="暂无数据" v-if="!courseInfo.introduce"></el-empty>
        </el-tab-pane>
        <el-tab-pane label="评论" name="third" v-if="courseInfo.isTelecast === '1'">
          <div class="p_in_20 " style="border-bottom: 1px solid #e4e7ed;" v-for="message of messList" :key="message.id">
            <div class="flex_row fs_16" style="justify-content: space-between">
              <div class="fs_18 fw_bold" style="line-height: 3rem;">{{ message.userName }}</div>
              <div class="fs_14" style="line-height: 3rem;">{{ message.createTime }}</div>
            </div>
            <span class="fs_16 m_tb_10"
                  style="line-height:3rem;"
                  v-for="item of message.content"
                  :key="item.id">
              <span v-if="item.type==='text'"> {{ item.msg }}</span>
              <img class="emoji"
                   style="width: 20px;height: 20px;vertical-align: middle;"
                   v-else
                   :src="emojiUrl + emojiMap[item.msg]"
                   alt="表情">
            </span>
          </div>
          <el-empty description="暂无数据" v-if="!messList.length"></el-empty>
          <!--    分页  -->
          <el-pagination
              class="m_t_15"
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              v-on:current-change="currentChange_click"
              hide-on-single-page
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import {getCourseDetail, getCourseMessage} from '@/api/course'
import {cancelCollection_api, saveCollection_api} from "@/api/mine";
import shoucang from "assets/img/shoucang.png";
import shoucangSelect from "assets/img/shoucang_select.png";
import {mapGetters} from "vuex";
import {sessionGet} from "@/utils/local";
import defaultImg from '@/assets/image/default.png'
// eslint-disable-next-line no-unused-vars
import {emojiMap, emojiUrl} from "utils/emojiMap";
import loadVideo from '@/assets/image/loadVideo.png'
import {imgToRespomse} from '@/utils/imgToResponse'

export default {
  name: '',
  data: () => {
    return {
      input: '',
      courseInfo: {
        teacher: '',
        teacherInfo: '',
        types: 'html'
      },
      shoucang,
      shoucangSelect,
      playsinline: true,
      params: {
        telecastId: '',
        current: 1,
        size: 10,
        type: 1
      },
      // 评论
      messList: [],
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        controls: true, //是否显示控制条
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src: ''// url地址
          }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          // timeDivider: true, // 当前时间和持续时间的分隔符
          // durationDisplay: true, // 显示持续时间
          // remainingTimeDisplay: false, // 是否显示剩余时间功能
          // fullscreenToggle: true // 是否显示全屏按钮
          volumeControl: true
        }
      },
      total: 0,
      // userInfo: '',
      loading: false,
      isShoucang: false,
      lock: false,
      defaultImg,
      emojiMap,
      emojiUrl,
      loadVideo
    }
  },
  computed: {
    ...mapGetters(["isLogin",]),
    userInfo() {
      return sessionGet('userInfo')
    }
  },
  mounted() {
    let courseId = this.$route.query.courseId
    this.getCourseDetail(courseId)
  },
  methods: {
    imgToRespomse(url) {
      return imgToRespomse(url)
    },
    async getCourseDetail(id) {
      let roleType1 = ''
      if (this.userInfo) {
        roleType1 = this.userInfo.role_name === "student" ? 2 : 1
      } else {
        roleType1 = 0
      }
      await getCourseDetail({id: id, roleType: roleType1}).then(res => {
        let courseInfo = res.data
        this.isShoucang = courseInfo.collType === 1
        courseInfo.teacher = res.data.introduce.split('</br>')[0]
        courseInfo.teacherInfo = res.data.introduce.split('</br>')[1]
        this.courseInfo = courseInfo
        if (courseInfo.types === 'mp4') {
          this.playerOptions.sources[0].type = 'video/mp4';
          this.playerOptions.sources[0].src = this.m3u8Url(courseInfo.resourcesUrl)
          // this.playerOptions.sources[0].src = `https://www.wuhousmartedu.com/api/regionIndex/imgToResponse?urlStr=${courseInfo.resourcesUrl}`
        } else if (courseInfo.types === 'm3u8') {
          this.playerOptions.sources[0].type = 'application/x-mpegURL'
          this.playerOptions.sources[0].src = this.m3u8Url(courseInfo.resourcesUrl)
        }
      })

      await this.getCourseMessage(this.courseInfo)
    },
    getCourseMessage(courseInfo) {
      let params = this.params
      params.telecastId = courseInfo.telecastId
      getCourseMessage(params).then(res => {
        this.total = res.data.total
        this.dataProcessing(res.data.records)
      })
    },
    // 点击收藏
    async collection(id) {
      if (!this.userInfo) {
        this.$message.warning('请先登录');
        return this.$router.push({path: '/login'})
      }

      if (this.lock) {
        return this.$message.warning('请勿重复点击')
      }
      this.lock = true
      await saveCollection_api({resourcesId: id}).then(res => {
        this.lock = false
        if (res.code == 200) {
          this.$message.success('收藏成功');
          this.courseInfo.collectionId = res.data.id
          this.isShoucang = true
          this.courseInfo.collectionNumber += 1
        }
      }).catch(() => {
        this.lock = false
      })
    },
    //取消收藏
    async cancelCollection(id) {
      if (this.lock) {
        return this.$message.warning('请勿重复点击!')
      }
      this.lock = true
      await cancelCollection_api({id}).then(res => {
        this.lock = false
        if (res.code == 200) {
          this.$message.warning('取消收藏');
          this.isShoucang = false
          this.courseInfo.collectionNumber -= 1
        }
      }).catch(() => {
        this.lock = false
      })
    },

    // 分页
    currentChange_click(current) {
      console.log('当前第', current, '页')
      this.params.current = current
      this.getCourseMessage(this.courseInfo)
    },
    // 跳转播放
    toPlay(url) {
      window.open(url)
    },
    // 处理消息
    dataProcessing(data) {
      this.messList = data.map(item => {
        let arr = [];
        item.content = (item.content.replace(/]/g, '],').replace(/\[/g, ',[').split(',')).filter(item => item)
        item.content.map(message => {
          arr.push({
            type: emojiMap[message] ? 'img' : 'text',
            msg: message
          })
        })
        item.content = arr
        return item
      })
    },
    // 处理m3u8视频地址
    m3u8Url(url) {
      let type = url.split(".")[url.split(".").length - 1];
      if (url.split(":")[0] == 'https' && type != 'm3u8') {
        return url
      } else {
        let arr = url.split('/')
        arr[2] = 'www.wuhousmartedu.com'
        return arr.join('/')
      }
    }
  }
}
</script>
<style scoped>
.box {
  margin: 1rem auto;
  width: 120rem;
}

.course-body {
  /*width : 116rem;*/
}

::deep .el-collapse-item__header {
  font-size: 1.8rem;
  font-weight: bold;
}

::deep .el-tabs__item {
  font-size: 1.8rem;
  font-weight: bold;
}

.course-info:hover {
  background-color: #ecf5ff;
}

.scang {
  display: flex;
  width: 18px;
  height: 18px;
}

.flex1 {
  flex: 1
}

.flex {
  display: flex;
  align-items: center
}

.w80 {
  width: 80%
}

.htmlLink a {
  color: #409eff;
}

.htmlLink:hover a {
  color: #ffffff;
}

/*
a {
  color: #409eff;
}

a:hover {
  color: #fff;
}*/
</style>
